import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const pl1 : ServiceInfoCardChildInterface = {
  boldDescription: "",
  bigImg: false,
  description: "Az italnagyker a nagykereskedelem mellett a kiskereskedelem felé is nyitni szeretett volna webáruház formájában. Ehhez a saját, egyedi fejlesztésű ERP rendszerükhöz kellett webáruházat fejleszteni, ami egyaránt alkalmas a kis- és nagyker partnereik kiszolgálására.",
  displayXo: true,
  reverse: true,
  title: "Nyitás a webes kereskedelem felé",
  picture: "/images/refs/pl/ref_color.jpg"
}

const pl2 : ServiceInfoCardChildInterface = {
  boldDescription: "",
  bigImg: false,
  description: "Minél gyorsabban az online értékesítés felé nyitnának, így egy kevésbé komplex, de mégis egyedi fejlesztésű webshopra esett a döntés. ",
  displayXo: true,
  reverse: false,
  title: "Jó áron jó termékek",
  picture: "/images/refs/pl/power_2.png"
}


export const plInfoCards = [
  pl1,
  pl2
]

