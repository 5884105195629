import { Reference } from "../reference-interface"
import { plInfoCards } from "../../service-info-card-interface/used_by/power-life-info-cards"

export const PowerLife : Reference = {
  monitorPic: "/images/imac_power_template.png",
  siteName: "Power Life webshop",
  siteDescription: "A Power Life számára a nagy rendelés szám miatt már nagyon fontos a könnyű és gyors feldolgozás így egy egyedi fejlesztésű webshop mellett döntöttek",
  siteUrl: "https://www.powerlife.hu/",
  cards: plInfoCards,
  referencePics: [
    "/images/refs/pl/pl_1.jpg",
    "/images/refs/pl/ref.jpg",
    "/images/refs/pl/pl_2.jpg",
  ],
  pageFunctions: [
    "Partner ERP",
    "Önálló adminfelület",
    "Egyedi design",
    "Kis- és nagykernek egyaránt",
    "Egyedi partner árak",
  ],
  reversePageFunctions: true,
}
